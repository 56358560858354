.pageHeader {
    height: 50mm;
    background-image: url("../../../../../assets/images/PageHeaderBG.png");
    background-size: cover;
    padding-bottom: -25mm;
    margin-bottom: -10mm;
    background-color: white;
}


.pageFooter {
    height: 17mm;
    background-image: url(../../../../../assets/images/PageFooterBG.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.header-row {
    border-left: solid #000 1px;
    border-bottom: dotted #000 1px;
    margin-bottom: 3mm;
    background-color: #ffffff;
}

.page-content {
    padding: 7mm;
    background-color: white;
    overflow: auto;
}

.header-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    margin-left: 0.75rem;
}

.table-bordered > :not(caption) > * {
    border-width: 1px 0;
}



@media print {
    @page {
        margin: 5mm 2mm;
    }

    td {
        padding: 2mm;
        /*background-color: #e7e7e7 !important;*/
        border-color: black;
        border-left: solid 1px !important;
    }

    td:last-child {
        border-right: solid 1px !important;
    }

    tr.silver-row > td {
        background-color: silver !important;
        padding: 1mm !important;
    }
}
