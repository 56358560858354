html {
    font-size: 12px;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #606060;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace
}

@font-face {
    font-family: Roboto;
    src: url(./fonts/Roboto-Regular.ttf)
}

.header-bg {
    background-color: #434343 !important;
    color: silver !important;
}

.nav-link {
    color: #000 !important;
}

.modal-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    opacity: 50%;
    background-color: grey
}

.App {
    text-align: center
}

.App-logo {
    height: 40vmin;
    pointer-events: none
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin 20s linear infinite;
        animation: App-logo-spin 20s linear infinite
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #fff
}

.App-link {
    color: #61dafb
}

@-webkit-keyframes App-logo-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes App-logo-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

body {
    min-height: 100%;
    background-color: #fff;
    font-family: "Roboto", Arial, sans-serif
}

thead {
    background-color: white;
}

@media screen {
    th {
        color: white;
        background-color: hsla(0, 0%, 48.2%, 1) !important;
    }
}

td {
    /*background-color: white !important;*/
}

.page-header {
    /*height: 60px;*/
    padding-left: 0;
    padding-right: 0;
    /*background: rgb(255,255,255);*/
    /*background: linear-gradient(90deg, rgba(255,255,255,0.9948354341736695) 0%, rgba(255,0,0,0.592874649859944) 100%);*/
    background: rgb(255, 158, 158);
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(225, 220, 220, 0.592874649859944) 100%);
}

.page-footer {
    height: 40px
}

.page-content {
    width: 100%;
    margin-top: 60px;
    height: calc(100vh - 100px);
    min-height: 500px;
    overflow: auto;
    overflow-scrolling: auto;
    background-image: url(./assets/images/pagebg.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: space;
    background-blend-mode: overlay;
    background-color: #3f2f2f;
}

.table-no-padding td {
    padding: 0 !important
}

.modal-custom-width {
    max-width: none !important
}

.w-95 {
    width: 95% !important;
}

.w-90 {
    width: 90% !important;
}

.w-1200px {
    width: 1200px !important
}

.w-600px {
    width: 600px !important
}

.w-480px {
    width: 480px !important
}

.w-340px {
    width: 340px !important
}

.w-320px {
    width: 320px !important
}

.w-300px {
    width: 300px !important
}

.w-240px {
    width: 240px !important
}

.w-180px {
    width: 180px !important
}

.w-160px {
    width: 160px !important
}

.w-140px {
    width: 140px !important
}

.w-120px {
    width: 120px !important
}

.w-30px {
    width: 30px !important
}

.w-50px {
    width: 50px !important
}

.w-60px {
    width: 60px !important
}

.w-75px {
    width: 75px !important
}

.w-100px {
    width: 100px !important
}

.w-100vw {
    width: 100vw !important
}

.ml-25px {
    margin-left: 25px !important
}

.max-w-none {
    max-width: none !important
}

.max-h-45vh {
    max-height: 45vh
}

.max-h-60vh {
    max-height: 60vh
}

.max-h-65vh {
    max-height: 65vh
}

.max-h-70vh {
    max-height: 70vh
}

.max-h-75vh {
    max-height: 75vh
}

.max-h-80vh {
    max-height: 80vh
}

.min-h-400px {
    min-height: 400px;
}

.scrollable {
    display: block;
    overflow: auto
}

.scrollable > table > thead > tr {
    font-weight: bold;
    color: white;
    background-color: hsla(0, 0%, 48.2%, 1) !important;
    border: none;
}

.scrollable > table > thead {
    top: -12px;
    background-color: hsla(0, 0%, 48.2%, 1) !important;
    /*z-index: 1;*/
}

.lh-2em {
    line-height: 2em !important
}

.lh-2-5em {
    line-height: 2.5em !important
}

.react-datepicker-wrapper {
    min-width: 120px !important
}

.table-no-padding .form-control {
    margin-bottom: .25em
}

.min-width-450px {
    min-width: 450px !important
}

.pointer {
    cursor: pointer !important
}

.Toastify__toast-container {
    width: 480px !important
}

#table-oven-temps input {
    text-align: right;
    padding-right: .25rem !important
}

.modal-full-size .modal-dialog {
    width: 100% !important;
    max-width: none !important
}

.modal-50 .modal-dialog {
    width: 50% !important;
    max-width: none !important
}

.modal-90 .modal-dialog {
    width: 90% !important;
    max-width: none !important
}

.modal-page-size .modal-dialog {
    width: 303mm !important;
    max-width: none !important
}

.readonly-date-picker .react-datepicker-wrapper {
    width: 100%
}

@media print {
    .btn {
        display: none !important
    }

    td {
        padding: 0 !important;
        margin: 0 !important
    }

    .pagebreak {
        page-break-before: always;
    }

    .pagebreak-after {
        page-break-after: always;
    }
}

.nav-pills > li, .tabs-right-align > .nav-tabs > li {
    float: none;
    display: inline-block;
    zoom: 1
}

.nav-pills, .tabs-right-align > .nav-tabs {
    text-align: right
}

.w-20 {
    width: 20%
}

.daily-full-report td {
    padding-right: 3px !important;
    vertical-align: middle !important
}

.daily-full-report td.align-top {
    vertical-align: top !important
}

.daily-full-report td.align-bottom {
    vertical-align: bottom !important
}

.daily-full-report td.no-padding {
    padding: 0 !important
}

.daily-full-report input {
    max-width: 120px;
    text-align: end;
    padding: 0;
    margin: 0 !important
}

.daily-full-report .casa input {
    max-width: 100%;
    text-align: start
}

.daily-full-report .casa input .text-right {
    text-align: end
}

.production-report-legenda {
    display: block;
    position: absolute;
    top: 70px;
    right: 30px;
    width: 280px;
    height: 280px;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid silver;
    box-shadow: 1px 1px 1px 1px grey;
    font-size: small;
    padding: 12px;
    opacity: .25
}

.production-report-legenda:hover {
    opacity: 1
}

td.green {
    background-color: green
}

td.red {
    background-color: red
}

td.silver {
    background-color: silver
}

.print {
    display: none
}

tr.table-filter-row {
    height: 50px !important;
    background-color: #d2691e
}

tr.table-filter-row > td {
    vertical-align: middle !important;
    padding: 2px !important;
    margin-bottom: 2px !important
}

.bg-primary-light {
    background-color: rgba(0, 123, 255, .25) !important
}

.bg-secondary-light {
    background-color: hsla(0, 0%, 48.2%, .25) !important
}

.cursor-pointer {
    cursor: pointer
}

#tableForm76 {
    overflow-y: auto;
    height: 45vh /* !!!  HEIGHT MUST BE IN [ vh ] !!! */
}

#tableForm76 > thead, #tableForm76 > tr {
    position: sticky;
    top: -1px;
    background-color: silver
}

@media print {
    * {
        overflow: visible !important
    }

    @page {
        size: A4 portrait;
    }

    @page landscaped {
        size: A4 landscape;
    }

    .landscape {
        page: landscaped;
    }

    button {
        display: none
    }

    h2 {
        font-size: 1.5em;
        text-align: left
    }

    .no-print {
        display: none !important
    }

    .print {
        display: block !important
    }

    .card {
        border: none !important
    }

    print-no-shadow {
        box-shadow: none !important
    }

    .card-body, .card-footer, .card-header {
        padding: 0 !important;
        border: none !important;
        box-shadow: none !important
    }

    table {
        margin: 0 !important
    }

    td, th {
        padding: 0 !important
    }

    .print-no-bg {
        background-color: white !important;
    }

    table.print-no-bg > thead > tr > th {
        background-color: white !important;
        color: black !important;
        font-weight: bold !important;
    }

    .tr-bordered {
        border-bottom-color: silver !important;
        border-bottom-width: 1px !important;
        border-bottom-style: solid !important;
    }

    .daily-full-report td {
        padding-right: 0 !important
    }

    input {
        border: none !important
    }

    .table-hover tbody tr:hover {
        color: #ff0 !important;
        background-color: brown
    }

    .table-bordered {
        border-width: 1px !important;
    }
}

.text-danger-light {
    color: #ff6d7b !important
}

.bg-danger-light {
    background-color: rgba(255, 109, 123, 0.24) !important
}

.bg-success-light {
    background-color: rgba(37, 205, 0, 0.38) !important
}

.table-container {
    overflow: auto;
}

thead {
    position: sticky;
    top: -1px;
}

.form-label {
    margin-bottom: 0 !important;
    margin-top: 0.75rem !important;
}

/*# sourceMappingURL=main.0b5deee1.chunk.css.map */

.scrollable-block-25 {
    max-height: 25vh;
    overflow-x: auto;
    overflow-y: auto;
}

.scrollable-block-75 {
    height: 75vh;
    overflow-x: auto;

    overflow-y: auto;
}

.y-scrollable-block-75 {
    height: 75vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.y-scrollable-block-50 {
    height: 50vh;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-right: 5px;
}

.table-small-lines {
    line-height: 13px;
}

.nav-item.nav-link.active {
    font-weight: bold !important;
    color: white !important;
    background-color: #0d6efd !important;
}

.editable-table-cell {

}

.editable-table-cell:hover {
    border: solid 1px blue;
}

.capitalize-first-letter::first-letter {
    text-transform: uppercase;
}
