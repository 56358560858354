/*table, tr, td {*/
/*    background-color: white;*/
/*}*/

/*table.no-border > tbody > tr > td {*/
/*    border: none !important;*/
/*}*/
table > thead > tr > th {
    color: white !important;
}

@media print {
    table > tbody > tr > td {
        padding: 2pt !important;
        border: 1pt solid #000 !important;
    }

    table > thead > tr > th {
        padding: 2pt !important;
        border: 2pt solid #000 !important;
        font-weight: bold;
        background-color: black !important;
        color: white;
    }
}
