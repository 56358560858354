/*table, tr, td {*/
/*    background-color: white !important;*/
/*}*/

/*table.no-border > tbody > tr > td {*/
/*    border: none !important;*/
/*}*/

@media print{@page {size: portrait}}

@media print {
    table.items > tbody > tr > td {
        padding: 2pt !important;
        border: 1pt solid #000 !important;
    }

    table.items > thead > tr > th {
        padding: 2pt !important;
        border: 1pt solid #000 !important;
        font-weight: bold;
        background-color: silver !important;
        color: black;
    }
}
